<!--
   /**
      * propertySystem.vue
      * @module {src/modules/public}
      * @desc 前端关于我们
      * @author shiqiu
      * @date 2021-01-20
      */
-->
<template>
  <div>
    <div v-for="(indexPro,proKey) in proList" :key="proKey">
    <el-row type="flex" justify="center" style="margin-top: 5vw;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10">
                      <el-col :span="24">
                        <p class="bigTitle">{{indexPro.introduce_zh}}</p>
                        <p class="lineTitle"></p>
                        <p class="enTitle">{{indexPro.introduce_en}}</p>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10" class="margin_b_20 margin_t_20" type="flex" justify="center">
                      <el-col :xs="23" :sm="23" :md="20" :lg="20" :xl="20">
                        <p class="introduce">{{indexPro.introduce}}</p>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" style="margin-top: 10px;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10" class="margin_b_40">
                      <el-col :span="24">
                        <p class="bigTitle">系统框架</p>
                        <p class="lineTitle"></p>
                        <p class="enTitle">SYSTEM FRAMEWORK</p>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10"  type="flex" justify="center">
                      <el-col :span="20" class="margin_b_40">
                        <p style="font-size: 20px;margin-bottom: 20px">{{indexPro.title}}</p>
                        <img :src="indexPro.system_img" style="width: 100%">
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" style="margin-top: 10px;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10" class="margin_b_20">
                      <el-col :span="24">
                        <p class="bigTitle">{{indexPro.introduce_three_zh}}</p>
                        <p class="lineTitle"></p>
                        <p class="enTitle">{{indexPro.introduce_three_en}}</p>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10">
                      <el-col :span="24" v-for="(preview,previewKey) in indexPro.previewList" :key="previewKey" style="background: #FEF8EE;padding: 40px; margin-bottom: 20px">
                        <p style="margin-bottom: 20px; font-size: 24px; color:#333; font-weight: 100">{{preview.name}}</p>
                        <img :src="preview.preview_img" style="width: 90%; margin-left: 5%">
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Login',
  components: {},
  data () {
    return {
      proList: [
        {
          introduce_img:'/static/images/about.jpg',
          introduce_zh:'物业管理',
          introduce_en:'The property management',
          introduce:'为各类型物业公司（社区、写字楼、综合体、专业市场、购物中心等）提供物业管理服务，大至物业3D可视图，细至每个单元的基础数据（包括平面图、面积、设备设施信息等）、租赁历史（包括租金、时长等）、能耗（包括水、电、煤等）、物业周边商圈的运营情况等，帮助物业对这些高度体现企业资产生命周期的重要数据进行管理。',
          system_img:'/static/images/cloud01-1.png',
          title:'物业管理有七大功能：信息管理、内控管理、客户服务、人力资源管理、库存管理、账务管理、系统管理',

          introduce_three_zh:'功能预览',
          introduce_three_en:'PREVIEW FUNCTION',
          previewList:[
            {
              name:"首页-数据统计中心：实时查看各项数据，了如指掌",
              preview_img:'/static/images/cloud06.jpg',
            },
            {
              name:"信息管理-项目资料：小区、楼栋、楼层各项信息可视化编辑",
              preview_img:'/static/images/cloud07.png',
            },
            {
              name:"内控管理-工单管理：多种派单模式，一键派单、自动派单、抢单。工单完成率迅速提升",
              preview_img:'/static/images/cloud08.jpg',
            },
            {
              name:"内控管理-办公审批",
              preview_img:'/static/images/cloud19.png',
            },
            {
              name:"内控管理-排班管理：支持添加班次、添加轮班、添加出勤、标记休息等灵活多变的交互操作，保证排班过程简单高效",
              preview_img:'/static/images/cloud09.jpg',
            },
            {
              name:"内控管理-巡更管理：多种巡更模式，让巡更更灵活，巡更进度可视化跟踪",
              preview_img:'/static/images/cloud10.jpg',
            },
            {
              name:"客户服务-客服中心：快速指派、处理各种投诉、报修、维修",
              preview_img:'/static/images/cloud16.png',
            },
            {
              name:"人力资源管理-员工管理",
              preview_img:'/static/images/cloud17.png',
            },
            {
              name:"人力资源管理-KPI考核",
              preview_img:'/static/images/cloud18.png',
            },
            {
              name:"库存管理-库存管理",
              preview_img:'/static/images/cloud11.jpg',
            },
            {
              name:"财务管理-财务统计：各项收费缴费数据的统计与分析，包括成本分析、员效率统计分析等。",
              preview_img:'/static/images/cloud14.png',
            },
            {
              name:"财务管理-财务报表：收费项目一目了然。",
              preview_img:'/static/images/cloud15.jpg',
            }

          ],

        }
      ],
    }
  },
  mounted () {

  },
  watch: {

  },
  methods: {

  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
body {
  background: #fff !important;
}
</style>
